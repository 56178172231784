var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('LiefengContent',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("用户报表")]},proxy:true},{key:"toolsbarLeft",fn:function(){return undefined},proxy:true},{key:"contentArea",fn:function(){return [_c('List',{staticClass:"clist"},[_c('Card',[_c('div',{staticClass:"searchStyle",attrs:{"slot":"title"},slot:"title"},[(
              _vm.indexList[_vm.columnSlt.slt] != _vm.indexList['时间'] &&
              _vm.indexList[_vm.rowSlt.slt] != _vm.indexList['时间']
            )?_c('div',{staticClass:"searchTab"},[_vm._v(" 日期："),_c('Select',{staticStyle:{"width":"100px"},model:{value:(_vm.reportDateType),callback:function ($$v) {_vm.reportDateType=$$v},expression:"reportDateType"}},_vm._l((_vm.dataList['时间']),function(item,index){return _c('Option',{key:'T' + index,attrs:{"value":item.id}},[_vm._v(_vm._s(item.title))])}),1),(_vm.reportDateType > 0)?_c('DatePicker',{staticStyle:{"width":"150px"},attrs:{"type":_vm.reportDateType == 1 || _vm.reportDateType == 2
                  ? 'year'
                  : _vm.reportDateType == 5
                  ? 'date'
                  : 'month',"placeholder":"选择日期"}}):_vm._e(),(_vm.reportDateType == 2)?_c('Select',{staticStyle:{"width":"100px"}},[_c('Option',{attrs:{"value":"1"}},[_vm._v("第一季")]),_c('Option',{attrs:{"value":"2"}},[_vm._v("第二季")]),_c('Option',{attrs:{"value":"3"}},[_vm._v("第三季")]),_c('Option',{attrs:{"value":"4"}},[_vm._v("第四季")])],1):_vm._e(),(_vm.reportDateType == 4)?_c('Select',{staticStyle:{"width":"100px"}},[_c('Option',{attrs:{"value":"1"}},[_vm._v("第一周")]),_c('Option',{attrs:{"value":"2"}},[_vm._v("第二周")]),_c('Option',{attrs:{"value":"3"}},[_vm._v("第三周")]),_c('Option',{attrs:{"value":"4"}},[_vm._v("第四周")])],1):_vm._e()],1):_vm._e(),(
              _vm.indexList[_vm.columnSlt.slt] != _vm.indexList['行政区域'] &&
              _vm.indexList[_vm.rowSlt.slt] != _vm.indexList['行政区域']
            )?_c('div',{staticClass:"searchTab"},[_vm._v(" 行政区域："),_c('Cascader',{staticStyle:{"width":"200px"},attrs:{"data":_vm.dataList['行政区域'],"change-on-select":""}})],1):_vm._e(),(
              _vm.indexList[_vm.columnSlt.slt] != _vm.indexList['机构'] &&
              _vm.indexList[_vm.rowSlt.slt] != _vm.indexList['机构']
            )?_c('div',{staticClass:"searchTab"},[_vm._v(" 机构："),_c('Cascader',{staticStyle:{"width":"200px"},attrs:{"data":_vm.dataList['机构'],"change-on-select":""}})],1):_vm._e(),(
              _vm.indexList[_vm.columnSlt.slt] != _vm.indexList['栏目'] &&
              _vm.indexList[_vm.rowSlt.slt] != _vm.indexList['栏目']
            )?_c('div',{staticClass:"searchTab"},[_vm._v(" 栏目："),_c('Cascader',{staticStyle:{"width":"300px"},attrs:{"data":_vm.dataList['栏目'],"change-on-select":""}})],1):_vm._e(),(
              _vm.indexList[_vm.columnSlt.slt] != _vm.indexList['统计指标'] &&
              _vm.indexList[_vm.rowSlt.slt] != _vm.indexList['统计指标']
            )?_c('div',{staticClass:"searchTab"},[_vm._v(" 统计指标："),_c('Cascader',{staticStyle:{"width":"200px"},attrs:{"data":_vm.dataList['统计指标'],"change-on-select":""}})],1):_vm._e(),_c('div',{staticClass:"searchTab"},[_c('Button',{staticStyle:{"width":"80px"},attrs:{"type":"info","icon":"ios-sea rch"},on:{"click":_vm.saveReport}},[_vm._v("查询")])],1)]),_c('Table',{ref:"lftable",staticStyle:{"margin":"15px 0"},attrs:{"border":"","columns":_vm.talbeColumns,"data":_vm.tableData}}),(_vm.chartSlt.slt == '折线图')?_c('VeLine',{attrs:{"data":_vm.chartData,"settings":{ label: { show: true } }}}):_vm._e(),(_vm.chartSlt.slt == '柱状图')?_c('VeHistogram',{attrs:{"data":_vm.chartData,"settings":{ label: { show: true } }}}):_vm._e(),(_vm.chartSlt.slt == '饼状图')?_c('VePie',{attrs:{"data":_vm.chartData,"settings":{ label: { show: true } }}}):_vm._e()],1)],1)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }